import { Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Input, InputLabel } from '../../../components/Input';
import Snackbar from '../../../components/snackbar';
function Details({ user }) {
    const { first_name, last_name, email, street_address, city, state, zip, company, phone, interested_in } = user;
    const [message, setMeassage] = React.useState('');
    const dispatch = useDispatch();
    const [profile, setProfile] = React.useState({
        first_name,
        last_name,
        email,
        street_address,
        interested_in,
        city,
        state,
        zip,
        company,
        phone,
    });

    const onChange = (event) => {
        setProfile({ ...profile, [event.target.name]: event.target.value });
    };



    return (
        <div className='md:pl-30 md:pr-10 lg:pr-10 xl:pr-150 sm:pr-1 pt-29 pb-150'>
            <Snackbar open={!!message ? true : false} setError={setMeassage} message={message} bg='#50bfd8' />
            <form >
                <Grid container justify='space-between'>
                    <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
                        <InputLabel text='First Name' />
                        <Input
                            placeholder='Your First Name'
                            className='mt-10 mb-20'
                            value={profile.first_name}
                            name='first_name'
                            onChange={onChange}
                        />
                        <InputLabel text='Last Name' />
                        <Input
                            placeholder='Your Last Name'
                            className='mt-10 mb-20'
                            value={profile.last_name}
                            name='last_name'
                            onChange={onChange}
                        />
                        <InputLabel text='Street Address' />
                        <Input
                            placeholder='Street Address'
                            className='mt-10 mb-20'
                            value={profile.street_address}
                            name='street_address'
                            onChange={onChange}
                        />
                        <InputLabel text='City' />
                        <Input placeholder='Yout City Name' className='mt-10 mb-20' value={profile.city} name='city' onChange={onChange} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                                <InputLabel text='State' />
                                <Input placeholder='State' className='mt-10 mb-20' value={profile.state} name='state' onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                <InputLabel text='Zip' />
                                <Input
                                    placeholder='Zip'
                                    type='number'
                                    className='mt-10 mb-20'
                                    value={profile.zip}
                                    name='zip'
                                    onChange={onChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
                        <InputLabel text='Company' />
                        <Input
                            placeholder='Company Name'
                            className='mt-10 mb-20'
                            value={profile.company}
                            name='company'
                            onChange={onChange}
                        />
                        <InputLabel text='Phone No' />
                        <Input
                            placeholder='Phone No'
                            type='number'
                            className='mt-10 mb-20'
                            placeholder='Email'
                            className='mt-10 mb-20'
                            value={profile.phone}
                            name='phone'
                            onChange={onChange}
                        />
                        <InputLabel text='Email' />
                        <Input
                           
                            placeholder='Email'
                            type='email'
                            className='mt-10 mb-20'
                            value={profile.email}
                            name='email'
                         
                        />
                        {/* <div className='flex justify-between'>
                            <InputLabel text='Notifications' />
                            <div>
                                <IOSSwitch />
                            </div>
                        </div> */}
                    </Grid>
                </Grid>
             
            </form>
        </div>
    );
}

export default Details;
